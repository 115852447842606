import React from 'react'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../../components/SiteMetadata'

import Layout from '../../components/Layout'
import PageBanner from '../../components/PageBanner/PageBanner'
import BlogRoll from '../../components/BlogRoll'

const BlogIndexPage = () => {
  const { title } = useSiteMetadata()
  return (
    <Layout>
      <Helmet>
        <title>{`Latest News | ${title}` }</title>
      </Helmet>
      <PageBanner title="Latest News" />

      <section className="section">
        <div className="container pb-48">
          <div className="">
            <BlogRoll />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndexPage
