import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { formatDistance } from 'date-fns'
import Img from 'gatsby-image'

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="grid col-span-6 md:grid-cols-12 gap-48 text-white">
        {posts && posts.map(({ node: post }) => {
            const postDate = formatDistance(new Date(post.frontmatter.date), new Date())

            return (
            <div className="col-span-6 lg:col-span-4 relative" key={post.id}>
              <Link
                to={post.fields.slug}
              >
                <article className="h-full bg-gray-500">
                  <Img fluid={post.frontmatter.featuredimage.childImageSharp.fluid } />
                  <div className=" p-16">
                    <header>
                      <h3 className="post-meta block text-24 leading-32 mb-8">
                          {post.frontmatter.title}
                      </h3>
                    </header>
                    <p className="opacity-80 mb-56 leading-24">
                      {post.excerpt}
                    </p>
                    <div className="absolute bottom-0 flex space-x-32 mb-16">
                      <Link className="text-red underline" to={post.fields.slug}>
                        Read full post
                      </Link>
                      <time datetime={post.frontmatter.date} className="opacity-50 italic">
                        {`${postDate} ago`}
                      </time>
                    </div>
                  </div>
                </article>
              </Link>
            </div>
            )
          })}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "news-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 160)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 608, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
